// The current possible role values from RBAC
import { isoLanguageCode } from '../../utils/locale';

export const rbacRoles = [
  'FA_READ_ONLY',
  'FA_READ_WRITE',
  'IS_READ_WRITE',
  'SELF_ACCESS',
  'SIGFIG_FULL_AGENT',
] as const;
export type RbacRole = typeof rbacRoles[number];

export interface Auth0User {
  'https://fc.sigfig.com:baseURL': string;
  'https://fc.sigfig.com:deliveryChannel': string;
  'https://fc.sigfig.com:frontEndJwt': string;
  'https://fc.sigfig.com:inContextPartyId': string;
  'https://fc.sigfig.com:locale': isoLanguageCode;
  'https://fc.sigfig.com:returnURL': string;
}

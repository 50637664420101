import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import * as React from 'react';

import { Grid, Typography } from '@sigfig/digital-wealth-core';

export interface Props {
  color?: string;
  dataQa?: string;
  label: string | null;
  value?: string;
}

export const AssetClassAllocation: React.FC<Props> = ({ color, dataQa = 'asset-class-allocation', label, value }) => {
  return (
    <Grid container data-qa={dataQa}>
      <Grid item xs="auto">
        <FiberManualRecordIcon sx={{ color, fontSize: 16, mr: 1, mt: 0.5 }} />
      </Grid>
      <Grid item xs>
        <Typography component="p" data-qa={`${dataQa}-label`} sx={{ color: 'text.primary' }} variant="subtitle2">
          {label}
        </Typography>
        <Typography data-qa={`${dataQa}-value`} variant="body2">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

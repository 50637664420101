import React from 'react';

import { Stack, Typography } from '@sigfig/digital-wealth-core';

export interface Props {
  dataQa?: string;
  heading: string;
  isPlayback?: boolean;
}

export const ProgramDetailsHeader: React.FC<Props> = ({
  children,
  dataQa = 'program-details-header',
  heading,
  isPlayback,
}) => {
  return (
    <Stack spacing={1}>
      <Typography
        component={isPlayback ? 'h5' : 'h4'}
        data-qa={dataQa}
        sx={{
          textDecoration: 'underline',
          textDecorationColor: ({ palette }) => palette.primary.main,
          textDecorationThickness: 2,
        }}
        variant="subtitle2"
      >
        {heading}
      </Typography>

      {children}
    </Stack>
  );
};

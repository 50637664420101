import { useMemo } from 'react';

import { AsyncResult, ContactType, useGetClientInfo } from '@sigfig/digital-wealth-core';

import { useAuth0 } from '../auth0';

interface ClientInfo {
  email: string;
  name?: string;
}

type ClientInfoData = ReturnType<typeof useGetClientInfo>['data'];

const getClientName = (data?: ClientInfoData): string | undefined => {
  const partyPerson = data?.client?.party?.partyPerson;
  return partyPerson?.givenName && partyPerson.familyName
    ? `${partyPerson.givenName} ${partyPerson.familyName}`
    : partyPerson?.displayName ?? undefined;
};

const getClientEmail = (data?: ClientInfoData): string => {
  const party = data?.client?.party;
  const primaryEmail = party?.partyContacts?.find(contact => contact.isPrimary && contact.type === ContactType.EMAIL);
  if (primaryEmail) {
    return primaryEmail.contact;
  }
  const email = party?.partyContacts?.find(contact => contact.type === ContactType.EMAIL);
  return email ? email.contact : '';
};

type Variables = { skip?: boolean };

export const useClientInfo = (variables?: Variables): AsyncResult<ClientInfo> => {
  const { user } = useAuth0();
  const partyId = user?.inContextPartyId;

  const { data: clientInfo, loading: clientInfoLoading, error: clientInfoError } = useGetClientInfo({
    variables: { partyId: partyId ?? '' },
    skip: variables?.skip || !partyId,
  });

  const data = useMemo(() => {
    if (clientInfoLoading || !clientInfo) {
      return undefined;
    }
    return {
      email: getClientEmail(clientInfo),
      name: getClientName(clientInfo),
    };
  }, [clientInfo, clientInfoLoading]);

  return {
    data,
    error: clientInfoError,
    loading: clientInfoLoading,
  };
};

// eslint-disable-next-line no-restricted-imports
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useMemo, useState } from 'react';

import { ClaimSet, Locale } from '@sigfig/digital-wealth-core';

import { parseLocale } from '../../utils/locale';

import { Auth0User, RbacRole } from './types';
import { getUserRole } from './utils';

interface User {
  baseUrl: string;
  deliveryChannel: string;
  inContextPartyId: string;
  jwt: string;
  locale: Locale;
  partyIdFA: string;
  returnUrl: string;
  role?: RbacRole;
}

interface UseAuth0 extends Pick<Auth0ContextInterface<User>, 'logout' | 'isAuthenticated' | 'isLoading'> {
  token?: string;
  user?: User;
}

const useAuth0Wrapper = (): UseAuth0 => {
  const { user, isAuthenticated, getAccessTokenSilently, error, logout, isLoading } = useAuth0<Auth0User>();
  const [token, setToken] = useState<string>();

  if (error) {
    throw error;
  }

  if (isAuthenticated && !user?.['https://fc.sigfig.com:frontEndJwt']) {
    throw new Error('User missing JWT');
  }

  useEffect(() => {
    const getAccessToken = async () => {
      if (isAuthenticated) {
        try {
          setToken(await getAccessTokenSilently());
        } catch (e) {
          console.error(e);
        }
      }
    };
    getAccessToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return useMemo(() => {
    const result: UseAuth0 = {
      isAuthenticated,
      isLoading,
      logout,
      token,
    };

    if (user) {
      const jwt = user['https://fc.sigfig.com:frontEndJwt'];
      const partyIdFA = jwtDecode<ClaimSet>(jwt).sub ?? '';

      result.user = {
        baseUrl: user['https://fc.sigfig.com:baseURL'],
        deliveryChannel: user['https://fc.sigfig.com:deliveryChannel'],
        inContextPartyId: user['https://fc.sigfig.com:inContextPartyId'],
        jwt,
        partyIdFA,
        locale: parseLocale(user['https://fc.sigfig.com:locale']),
        returnUrl: user['https://fc.sigfig.com:returnURL'],
        role: isAuthenticated ? getUserRole(user) : undefined,
      };
    }

    return result;
  }, [isAuthenticated, isLoading, logout, token, user]);
};

export { useAuth0Wrapper as useAuth0 };

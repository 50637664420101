import React from 'react';

import { Divider, Grid, useTheme } from '@sigfig/digital-wealth-core';

import { AssetClassAllocation } from '../AssetClassAllocation';

export interface Props {
  allocations: {
    key: string;
    label: string;
    value: string;
  }[];
  dataQa?: string;
}

export const PortfolioComposition: React.FC<Props> = ({ allocations, dataQa = 'portfolio-composition' }) => {
  const {
    bnsPortfolioSelection: { styles },
  } = useTheme();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {allocations.map(a => (
        <Grid data-qa={`${dataQa}-${a.label}`} item key={a.key} xs="auto">
          <AssetClassAllocation color={styles[a.key]} label={a.label} value={a.value} />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { AsyncResult, DropdownItem, getImageAssetUrl, HeaderDropdownItem } from '@sigfig/digital-wealth-core';

import { PageRoute } from '../../../App/routes';
import { useApp } from '../../../contexts/App';
import { useGetHeaderContent } from '../contentstack';

interface Data {
  advisor?: string;
  backButton?: string;
  backToDashboard?: string;
  backToPartner?: string;
  currentLanguage: string;
  languageOptions: DropdownItem[];
  logo: {
    alt: string;
    src: string;
  };
  subHeader: string;
  tooltip: {
    body: string;
    cta: string;
    header: string;
  };
}

/**
 * Some labels are set with value only if they are applicable for certain pages based on route location.
 */
export const useHeaderContent = (): AsyncResult<Data> => {
  const location = useLocation();
  const { contentOptions } = useApp();

  const { data: headerContent, error: headerContentError, loading: headerContentLoading } = useGetHeaderContent({
    variables: contentOptions,
  });

  const data: Data | undefined = useMemo(() => {
    if (headerContentError || headerContentLoading) {
      return undefined;
    }

    const content = headerContent?.all_header?.items?.[0];
    const languageOptions =
      content?.language_dropdownConnection?.edges?.[0]?.node?.items
        ?.filter((item): item is HeaderDropdownItem => item !== null)
        .map((item: HeaderDropdownItem) => ({
          label: item.display_value ?? '',
          value: item.data_value ?? '',
        })) ?? [];
    const isAccountSummaryPage = location.pathname.includes(PageRoute.AccountSummary);

    return {
      advisor: isAccountSummaryPage ? content?.advisor_text ?? '' : undefined,
      backToDashboard: isAccountSummaryPage ? undefined : content?.back_to_account_summary_button_text ?? '',
      backToPartner:
        isAccountSummaryPage || location.pathname.includes('/details')
          ? content?.back_to_partner_account_summary_text ?? ''
          : undefined,
      backButton: location.pathname.includes('/details')
        ? content?.back_to_account_summary_button_text ?? ''
        : undefined,
      currentLanguage: languageOptions.find(a => a.value === contentOptions.locale)?.value ?? '',
      languageOptions,
      logo: {
        alt: content?.logo_alt_text ?? '',
        src: getImageAssetUrl(content?.logoConnection),
      },
      subHeader: content?.subheader_text ?? '',
      tooltip: {
        cta: content?.help_button_text ?? '',
        header: content?.help_tooltip_header ?? '',
        body: content?.help_tooltip_content ?? '',
      },
    };
  }, [
    contentOptions.locale,
    headerContent?.all_header?.items,
    headerContentError,
    headerContentLoading,
    location.pathname,
  ]);

  return {
    data,
    error: headerContentError,
    loading: headerContentLoading,
  };
};

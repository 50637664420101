import { matchRoutes, RouteObject, useLocation } from 'react-router-dom';

import { PageRoute } from '../routes';

/**
 * Retrieve the page the user is currently viewing within the application.
 * @returns The route, if exists, otherwise undefined.
 */
export const useCurrentPage = (): PageRoute | undefined => {
  const location = useLocation();
  const routeList: RouteObject[] = Object.values(PageRoute).map(p => ({ path: p }));
  const matchingRoutes = matchRoutes(routeList, location);
  if (!matchingRoutes) {
    return;
  }

  return Object.values(PageRoute).find(p => matchingRoutes.find(r => p === r.route.path));
};

import React from 'react';

import { CircularProgress, Stack } from '@sigfig/digital-wealth-core';

export const Loading: React.FC = () => <CircularProgress disableShrink />;
export const PageLoading: React.FC = () => {
  return (
    <Stack sx={{ alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
      <CircularProgress disableShrink />
    </Stack>
  );
};

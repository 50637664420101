import * as React from 'react';

import { Box, Typography } from '@sigfig/digital-wealth-core';

export interface Props {
  dataQa?: string;
  label?: string;
  value?: {
    ariaLabel?: string;
    text?: string;
  };
}

export const SummaryGroup: React.FC<Props> = ({ dataQa, label, value }) => (
  <Box
    aria-label={value?.ariaLabel}
    component="dl"
    data-qa={`${dataQa}`}
    sx={{ marginBlockStart: 0, marginBlockEnd: 0 }}
    textAlign={{ xs: 'left', sm: 'center' }}
  >
    <Typography component="dd" data-qa={`${dataQa}-value`} variant="h6">
      {value?.text}
    </Typography>
    <Typography color="text.secondary" component="dt" data-qa={`${dataQa}-label`} variant="caption">
      {label}
    </Typography>
  </Box>
);

import React, { ComponentProps, FC } from 'react';

import { Alert, Box, Container, Grid, Link, RteContent, Skeleton } from '@sigfig/digital-wealth-core';

import { useApp } from '../../contexts/App';

import { useGetFooterContent } from './contentstack';

const isNotNullOrUndefined = <T,>(item: T | null | undefined): item is T => item !== null && item !== undefined;

export const Footer: FC<ComponentProps<typeof Box>> = ({ sx, ...rest }) => {
  const { contentOptions } = useApp();
  const { data, loading, error } = useGetFooterContent({ variables: contentOptions });
  const disclosures = (data?.all_bns_app_footer?.items?.[0]?.disclosures ?? []).filter(isNotNullOrUndefined);

  return (
    <Box
      data-qa="footer"
      sx={{
        backgroundColor: 'secondary.background',
        py: 6,
        ...sx,
      }}
      {...rest}
    >
      <Container component="footer">
        {loading && <Skeleton height={300} />}
        {error && <Alert contentOptions={contentOptions} error={error} severity="error" />}
        <Grid container direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 8 }}>
          {disclosures.map(
            (d, i) =>
              d.content && (
                <Grid item key={i} xs="auto">
                  <RteContent anchorComponent={DisclosureLink} data={d.content} />
                </Grid>
              ),
          )}
        </Grid>
      </Container>
    </Box>
  );
};

const DisclosureLink: ComponentProps<typeof RteContent>['anchorComponent'] = props => (
  <Link sx={{ color: 'inherit', textDecorationColor: 'inherit' }} {...props} />
);

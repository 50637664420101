import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Container } from '@sigfig/digital-wealth-core';

import { Footer, Header } from '../components';

import { useCurrentPage } from './hooks/useCurrentPage';
import { PageRoute } from './routes';

export const AppLayout: React.FC = () => {
  const currentPage = useCurrentPage();

  return (
    <Box data-qa="app-layout">
      <Header />
      {currentPage && [PageRoute.AccountSummary, PageRoute.Goals, PageRoute.OnboardingStep].includes(currentPage) ? (
        <Outlet />
      ) : (
        <Container component="main">
          <Outlet />
        </Container>
      )}
      <Footer mt={{ xs: 3, md: 6 }} />
    </Box>
  );
};

import { Auth0Provider } from '@auth0/auth0-react';
import React, { FC } from 'react';

import config from '../config';

export const AuthWrapper: FC = ({ children }) => {
  return (
    <Auth0Provider
      audience={`https://${config.auth0.audienceEnv}.sigfig.api`}
      clientId={config.auth0.clientId}
      domain={config.auth0.domain}
      redirectUri={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
};

import InfoIcon from '@mui/icons-material/Info';
import React, { FC } from 'react';

import { Box, Link, Stack, Typography } from '@sigfig/digital-wealth-core';

import { Portfolio } from '../../containers/PortfolioSelection/types';
import { PortfolioSelectionContent } from '../../hooks/portfolio-selection/contentstack';
import { ProductType } from '../../utils/symphony/types';

import { PortfolioComposition } from './PortfolioComposition';
import { PortfolioTableData } from './PortfolioTableData';
import { ProgramDetailsHeader } from './ProgramDetailsHeader';
import { getFeesTableData, getPerformanceAnalysisTableData, getSavingsCompositionData } from './utils';

export interface Props {
  dataQa?: string;
  isPlayback?: boolean;
  portfolio: Portfolio;
  portfolioSelectionContent?: PortfolioSelectionContent | null;
}

export const ProgramDetails: FC<Props> = ({
  dataQa = 'program-details',
  portfolioSelectionContent,
  portfolio,
  isPlayback,
}) => {
  const { allocations, productType } = portfolio;
  const isMutualFund = productType === ProductType.MUTUAL_FUND;
  const isSavings = productType === ProductType.SAVINGS;
  const {
    fees: feesContent,
    labels: labelsContent,
    performance_analysis: performanceContent,
    portfolio_composition: compositionContent,
  } = portfolioSelectionContent ?? {};

  return (
    <Stack data-qa={dataQa} spacing={3}>
      {isSavings && !isPlayback && (
        <Link
          data-qa={`${dataQa}-portfolio-selection-rate-manager-link`}
          href={labelsContent?.gic_rate_manager_link ?? ''}
          rel="noreferrer noopener"
          sx={{ color: 'info.dark', textDecorationColor: ({ palette }) => palette.info.dark }}
          target="_blank"
          variant="body2"
        >
          {labelsContent?.gic_rate_manager}
        </Link>
      )}

      {!!allocations.length && (
        <ProgramDetailsHeader
          dataQa="portfolio-composition-header"
          heading={(isMutualFund ? compositionContent?.heading : compositionContent?.gic_heading) ?? ''}
          isPlayback={isPlayback}
        >
          {allocations.map(({ fundAllocations }, index) => (
            <PortfolioComposition {...getSavingsCompositionData(fundAllocations, compositionContent)} key={index} />
          ))}
        </ProgramDetailsHeader>
      )}

      {isSavings && isPlayback && (
        <Link
          data-qa={`${dataQa}-playback-rate-manager-link`}
          href={labelsContent?.gic_rate_manager_link ?? ''}
          rel="noreferrer noopener"
          sx={{ color: 'info.dark', textDecorationColor: ({ palette }) => palette.info.dark }}
          target="_blank"
          variant="body2"
        >
          {labelsContent?.gic_rate_manager}
        </Link>
      )}

      {isMutualFund && (
        <ProgramDetailsHeader
          dataQa="performance-analysis-header"
          heading={performanceContent?.heading ?? ''}
          isPlayback={isPlayback}
        >
          <PortfolioTableData
            {...getPerformanceAnalysisTableData(performanceContent, allocations, compositionContent)}
          />
        </ProgramDetailsHeader>
      )}

      {isMutualFund && (
        <ProgramDetailsHeader dataQa={`${dataQa}-fees`} heading={feesContent?.heading ?? ''} isPlayback={isPlayback}>
          <PortfolioTableData {...getFeesTableData(allocations, feesContent)} />
        </ProgramDetailsHeader>
      )}
    </Stack>
  );
};

import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import { GetHeaderContent, GetHeaderContentVariables } from './__generated__/GetHeaderContent';

const query = loader('./getHeaderContent.gql');

export const useGetHeaderContent = (
  options?: QueryHookOptions<GetHeaderContent, GetHeaderContentVariables>,
): QueryResult<GetHeaderContent, GetHeaderContentVariables> => {
  return useContentstackQuery(query, options);
};

import React, { lazy, Suspense, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { sfPosthog } from '@sigfig/digital-wealth-core';

import { PageLoading } from '../components/Loading';

import { AuthedApp } from './AuthedApp';
import { AuthWrapper } from './AuthWrapper';
import { PageRoute, routes } from './routes';

const AccountAlreadyInProgress = lazy(() => import('../containers/AccountAlreadyInProgress'));
const AccountDetails = lazy(() => import('../containers/AccountDetails'));
const Dashboard = lazy(() => import('../containers/Dashboard'));
const ExpiredProfile = lazy(() => import('../containers/ExpiredProfile'));
const GoalsCreate = lazy(() => import('../containers/Goals/Create'));
const GoalsEdit = lazy(() => import('../containers/Goals/Edit'));
const Onboarding = lazy(() => import('../containers/Onboarding'));
const RetakeWrapper = lazy(() => import('./RetakeWrapper'));

function App(): unknown {
  const [selectedAccountNumber, setSelectedAccountNumber] = useState('');
  sfPosthog.useCaptureSpaPageViews(useLocation());

  return (
    <Suspense fallback={<PageLoading />}>
      <AuthWrapper>
        <Routes>
          <Route element={<AuthedApp />}>
            <Route element={<AccountAlreadyInProgress />} path={PageRoute.AccountAlreadyInProgress} />
            <Route
              element={<AccountDetails onSelectedAccountChange={setSelectedAccountNumber} />}
              path={PageRoute.AccountDetails}
            />
            <Route
              element={<Dashboard setSelectedAccountNumber={setSelectedAccountNumber} />}
              path={PageRoute.AccountSummary}
            />
            <Route element={<Dashboard setSelectedAccountNumber={setSelectedAccountNumber} />} path={PageRoute.Goals} />
            <Route element={<GoalsCreate />} path={PageRoute.GoalsCreate} />
            <Route element={<GoalsEdit />} path={PageRoute.GoalsEdit} />
            <Route element={<Onboarding />} path={PageRoute.Onboarding} />
            <Route element={<Onboarding />} path={PageRoute.OnboardingStep} />
            <Route element={<ExpiredProfile accountNumber={selectedAccountNumber} />} path={PageRoute.ExpiredProfile} />
            <Route element={<RetakeWrapper accountNumber={selectedAccountNumber} />} path={PageRoute.Retake} />
            <Route element={<Navigate to={routes.accountSummary()} />} path="*" />
          </Route>
        </Routes>
      </AuthWrapper>
    </Suspense>
  );
}

export default App;

import { AccessibilityContent, GetRecommendedProduct, QueryResult, RiskBandData } from '@sigfig/digital-wealth-core';

import { FundCategoryTypes, InterestFrequency, RateUnitType } from '../../__generated__/symphonyTypes';
import { PortfolioSelectionContent } from '../../hooks/portfolio-selection/contentstack';
import { GetModelPortfolioContent } from '../../hooks/portfolio-selection/contentstack/__generated__/GetModelPortfolioContent';
import { AssetClasses, SelectedModelPortfolios } from '../../hooks/portfolio-selection/symphony';
import { ProductType, ProductVariant } from '../../utils/symphony/types';

export interface AvailablePortfoliosData {
  accessibilityContent?: AccessibilityContent;
  contentPortfolioSelection: PortfolioSelectionContent;
  modelPortfolioContent: GetModelPortfolioContent;
  pageCount: number;
  planId?: string;
  planUpdateWorkflowId?: string;
  portfolios: Portfolio[] | Portfolio[][];
  recommendedProductData?: GetRecommendedProduct;
  riskBandsData?: RiskBandData;
  selectedModelPortfolios?: SelectedModelPortfolios[];
}

export interface Portfolio {
  allocations: Allocation[];
  benefits: string[];
  description: string | null;
  fee?: FundAllocationFee; // for mutual funds only
  infoUrl?: string;
  internalId: number;
  isAria: boolean;
  isHighRisk: boolean;
  isMarketLinkedGic: boolean;
  isSavingsAccelerator: boolean;
  minimumInvestment: string;
  name: string | null;
  productCode?: string | null;
  productType: ProductType;
  rates?: Rate | null;
  riskRange: {
    max: number | null;
    min: number | null;
  };
  seriesBaseName: string;
  seriesId: number;
  seriesTBenefits: string[];
  seriesType: ProductVariant | null;
  term?: string | null;
}

export interface Allocation {
  appropriatenessUrl: string | null;
  assetName: string;
  cusip: string | null;
  fee: FundAllocationFee;
  fundAllocations?: FundAllocation[];
  fundCategory: FundCategoryTypes;
  fundCategoryDisplay: string | null;
  fundFactsUrl: string | null;
  identifier: string;
  liveRate: LiveRate | null;
  name: string | null;
  performance: {
    fiveYear?: string | null;
    inception?: string | null;
    oneYear?: string | null;
    tenYear?: string | null;
    threeYear?: string | null;
  };
  savingsType: string | null;
  targetAllocation: string;
  term: Term | null;
  underlyingIdentifier: string | null;
}

export interface LiveRate {
  customerInitiated: boolean;
  gicType: string;
  interestBasis: number;
  interestFrequency: InterestFrequency;
  isCompoundRate?: string | null;
  issuer: string;
  maturityDate: SymphonyDateTime;
  pricingRequestId: number;
  rates: Rate;
  securityId: string;
  strikeDate: SymphonyDateTime | null;
  term: Term;
}

export interface Rate {
  interimRate: SymphonyDecimal | null;
  maximumRate: SymphonyDecimal | null;
  targetRate: SymphonyDecimal;
}

export interface Term {
  displayPeriod: number;
  displayUnit: RateUnitType;
  end: number | null;
  start: number | null;
  unit: RateUnitType | null;
}

export interface FundAllocation {
  actualAllocation?: string | null;
  name: AssetClasses;
  targetAllocation: string;
}

export interface FundAllocationFee {
  effectiveDate: string;
  mer: string;
}

export interface GetAvailablePortfoliosResult {
  data?: AvailablePortfoliosData;
  error?: Error;
  loading: boolean;
  refetch?: QueryResult['refetch'];
}

export enum FilterMode {
  Recommendation = 1,
  Suitable,
  NonSuitable,
}

export enum ProductDisclaimerTypes {
  GIC = 'gic',
  MER = 'mer',
  MUTUAL_FUND = 'mutual-fund',
  MUTUAL_FUND_MONEY_MARKET = 'mutual-fund-money-market',
  SCOTIA_FUND = 'scotia-fund',
}

import { Locale } from '@sigfig/digital-wealth-core';

import { LanguageType } from '../../__generated__/symphonyTypes';

export type isoLanguageCode = 'en' | 'fr';

const validLocales: string[] = [Locale.en_us, Locale.fr_ca];

const LOCALE_MAP = new Map<isoLanguageCode, Locale>([
  ['en', Locale.en_us], // TODO: https://sigfig.atlassian.net/browse/DA2-2393
  ['fr', Locale.fr_ca],
]);

const SYMPHONY_LANGUAGE_MAP = new Map<Locale, LanguageType>([
  [Locale.en_us, LanguageType.ENGLISH],
  [Locale.fr_ca, LanguageType.FRENCH],
]);

/**
 * Given a two character language code, return the ContentStack locale string or default to `en-us` if no match is found.
 * @param language - ISO 639-1 Two character language code, or default to `en`.
 * @returns ContentStack Locale.
 */
export const parseLocale = (isoCode?: isoLanguageCode, fallback = Locale.en_us): Locale =>
  isoCode && LOCALE_MAP.has(isoCode) ? (LOCALE_MAP.get(isoCode) as Locale) : fallback;

export const isValidLocale = (locale?: string): boolean => !!locale && validLocales.includes(locale);

/**
 * Given a locale, return the corresponding `LanguageType` used by Symphony. If the locale is invalid, return `ENGLISH`.
 * @param locale - The locale value set by the user or from the auth entity.
 * @param fallback - The fallback Language to return if locale is invalid.
 * @returns The Symphony `LanguageType`.
 */
export const getSymphonyLanguageType = (locale?: Locale, fallback = LanguageType.ENGLISH): LanguageType =>
  locale && SYMPHONY_LANGUAGE_MAP.has(locale) ? (SYMPHONY_LANGUAGE_MAP.get(locale) as LanguageType) : fallback;

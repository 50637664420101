import React, { useContext } from 'react';

import { ContentOptions, Locale, ManagedProductType, Partner, Product, useLocale } from '@sigfig/digital-wealth-core';

import config from '../../config';

export interface AppContext {
  brokerageAlias: string;
  contentOptions: ContentOptions;
  program: ManagedProductType;
}

const defaultAppContext: AppContext = {
  brokerageAlias: 'scotiaSecurities',
  contentOptions: {
    environment: config.contentstack.environment,
    locale: Locale.en_us,
    partner: Partner.BNS,
    product: Product.DigitalWealthPro,
    token: config.contentstack.deliveryToken,
  },
  program: ManagedProductType.DIGITAL_ADVICE_PRO,
};

const AppContext = React.createContext(defaultAppContext);

export const AppProvider: React.FC = ({ children }) => {
  const [locale] = useLocale();
  const value = { ...defaultAppContext };
  value.contentOptions.locale = locale ?? defaultAppContext.contentOptions.locale;
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = (): AppContext => useContext(AppContext);

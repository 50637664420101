import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import {
  GetBnsPortfolioSelectionContent,
  GetBnsPortfolioSelectionContent_all_bns_portfolio_selection_items,
  GetBnsPortfolioSelectionContent_all_bns_portfolio_selection_items_fees,
  GetBnsPortfolioSelectionContent_all_bns_portfolio_selection_items_labels_gic_labelsConnection_edges_node,
  GetBnsPortfolioSelectionContent_all_bns_portfolio_selection_items_performance_analysis,
  GetBnsPortfolioSelectionContent_all_bns_portfolio_selection_items_portfolio_composition,
  GetBnsPortfolioSelectionContentVariables,
} from './__generated__/GetBnsPortfolioSelectionContent';
import {
  GetModelPortfolioContent,
  GetModelPortfolioContent_all_model_portfolio_data_items,
  GetModelPortfolioContentVariables,
} from './__generated__/GetModelPortfolioContent';

export type PortfolioSelectionContent = GetBnsPortfolioSelectionContent_all_bns_portfolio_selection_items;
export type PortfolioCompositionContent = GetBnsPortfolioSelectionContent_all_bns_portfolio_selection_items_portfolio_composition;
export type PerformanceAnalysisContent = GetBnsPortfolioSelectionContent_all_bns_portfolio_selection_items_performance_analysis;
export type FeesContent = GetBnsPortfolioSelectionContent_all_bns_portfolio_selection_items_fees;
export type GicLabelsContent = GetBnsPortfolioSelectionContent_all_bns_portfolio_selection_items_labels_gic_labelsConnection_edges_node;

export type ModelPortfolioContent = GetModelPortfolioContent_all_model_portfolio_data_items;

const getBnsPortfolioSelectionContentQuery = loader('./portfolioSelectionContent.gql');
const getModelPortfolioContentQuery = loader('./modelPortfolioContent.gql');

export const useGetBnsPortfolioSelectionContent = (
  options?: QueryHookOptions<GetBnsPortfolioSelectionContent, GetBnsPortfolioSelectionContentVariables>,
): QueryResult<GetBnsPortfolioSelectionContent, GetBnsPortfolioSelectionContentVariables> => {
  return useContentstackQuery(getBnsPortfolioSelectionContentQuery, options);
};

export const useGetModelPortfolioContent = (
  options?: QueryHookOptions<GetModelPortfolioContent, GetModelPortfolioContentVariables>,
): QueryResult<GetModelPortfolioContent, GetModelPortfolioContentVariables> => {
  return useContentstackQuery(getModelPortfolioContentQuery, options);
};

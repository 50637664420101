import React, { ForwardedRef, forwardRef } from 'react';

import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@sigfig/digital-wealth-core';

export interface Props {
  dataQa?: string;
  tableData: {
    tableHeaders: {
      name: string;
      value: string;
    }[];
    tableRows: {
      link?: string;
      name: string;
      value: string;
    }[][];
  };
}

export const PortfolioTableData = forwardRef(
  ({ dataQa = 'portfolio-table-data', tableData }: Props, ref?: ForwardedRef<HTMLAnchorElement>) => {
    const tableHeaders = tableData.tableHeaders;
    const tableRows = tableData.tableRows;

    return (
      <TableContainer>
        <Table sx={{ width: 'auto' }}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((h, index) => (
                <TableCell component="th" data-qa={`${dataQa}-heading-${h.name}`} key={index}>
                  {h.value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cIndex) => (
                  <TableCell data-qa={`${dataQa}-${cell.name}-value`} key={cIndex}>
                    {cell.link ? (
                      <Link
                        href={cell.link}
                        ref={ref}
                        rel="noreferrer noopener"
                        sx={{
                          color: 'inherit',
                          textDecoration: 'underline',
                          textUnderlineOffset: { xs: '2px', md: '3px' },
                        }}
                        target="_blank"
                        variant="body2"
                      >
                        {cell.value}
                      </Link>
                    ) : (
                      cell.value
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  },
);

import {
  allNumericDateFormat,
  Disclaimer,
  formatCurrency,
  FormatPercentageOptions,
  formatPercentageString,
  GetRecommendedProduct,
  LanguageType,
  Locale,
} from '@sigfig/digital-wealth-core';

import { FundCategoryTypes, RateUnitType } from '../../__generated__/symphonyTypes';
import {
  ModelPortfolioContent,
  PortfolioCompositionContent,
  PortfolioSelectionContent,
} from '../../hooks/portfolio-selection/contentstack';
import { GetModelPortfolioContent } from '../../hooks/portfolio-selection/contentstack/__generated__/GetModelPortfolioContent';
import {
  AssetClasses,
  getProductType,
  getProductVariant,
  isAria,
  ModelPortfolio,
  ModelPortfolioAllocationFees,
  ModelPortfolioAllocationPerformance,
  ModelPortfolioAllocations,
  ModelPortfolioAllocationSecurity,
  ModelPortfolioAllocationsLiveRate,
  ModelPortfolioAssetClassAllocations,
  ModelPortfolioLocalizedProperties,
} from '../../hooks/portfolio-selection/symphony';
import { getSymphonyLanguageType } from '../../utils/locale';
import { ProductType } from '../../utils/symphony/types';

import { getSortOrderValue } from './hooks/useGetAvailablePortfolios';
import {
  Allocation,
  FilterMode,
  FundAllocation,
  LiveRate,
  Portfolio,
  ProductDisclaimerTypes,
  Rate,
  Term,
} from './types';

export enum GicHisaTypes {
  Cashable = 'Cashable',
  GuaranteedIncomeOptimizer = 'Guaranteed Income Optimizer',
  MarketLinked = 'Market Linked',
  NonRedeemable = 'Non-Redeemable',
  Redeemable = 'Redeemable',
  SavingsAccelerator = 'Savings Accelerator',
}

export const isPortfolioRecommended = (selectedPortfolio: Portfolio, recommendedPortfolios: Portfolio[]): boolean => {
  return recommendedPortfolios.some(
    portfolio =>
      portfolio.seriesId === selectedPortfolio.seriesId && portfolio.internalId === selectedPortfolio.internalId,
  );
};

/**
 * Look up and returns the portfolio composition table heading based using the key or `null` if the heading
 * cannot be found based on the asset class key.
 * @param data - The CMS data.
 * @param key - The key used to retrieve the portfolio composition headings from the CMS.
 * @returns The portfolio composition heading.
 */
export const getPortfolioCompositionHeading = (
  data: PortfolioCompositionContent | null | undefined,
  key: string,
): string | null => {
  switch (key) {
    case AssetClasses.FIXED_INCOME:
      return data?.fixed_income ?? null;
    case AssetClasses.FOREIGN_EQUITY:
      return data?.foreign_equity ?? null;
    case AssetClasses.CANADIAN_EQUITY:
      return data?.canadian_equity ?? null;
    case AssetClasses.CASH_EQUIVALENT:
      return data?.cash_gic ?? null;
    default:
      return null;
  }
};

/**
 * Find and return the value for a given attribute/key matching the user's locale/language mapping or `null`
 * if a localized property object matching the language or the attribute cannot be found.
 * @param properties - The localized properties for the portfolio.
 * @param attribute - The JSON attribute/key.
 * @param lang - The locale/language to return.
 * @returns The value for the given attribute or `null` if no matching locale or attribute in the localized properties.
 */
export const getLocalizedPropertyValue = (
  properties: ModelPortfolioLocalizedProperties[] | null,
  attribute: string,
  lang: LanguageType,
): string | null => (properties?.find(prop => prop.locale === lang) as Record<string, any>)[attribute] ?? null;

/**
 * Find and return the ContentStack model portfolio content that matches the portfolio's internalId and seriesBaseName
 * when the `filterMode` is Recommendation or if the model portfolio content is required on the Playback page
 * (i.e. Not a mutual fund or is a recommended portfolio).
 *
 * For each of the series, the internalId run from 1 - N , where N is num of model portfolios in that series.
 * seriesBaseName + internalId combination will provide unique target content.
 * seriesBaseName alone may defined in CMS for generic text whose entry must not contain internalId
 *
 * @param modelPortfolioContent - The model portfolio ContentStack data.
 * @param modelPortfolio - The ModelPortfolio.
 * @param filterMode - The portfolio selection filter criteria.
 * @param recommendedPortfolios - The recommended portfolios array.
 * @returns The content for the model portfolio.
 */
const getPortfolioContent = (
  modelPortfolioContent: GetModelPortfolioContent,
  modelPortfolio: ModelPortfolio,
  filterMode?: FilterMode,
  recommendedPortfolios?: Portfolio[],
): ModelPortfolioContent | undefined | null => {
  const shouldShowOnPlayback =
    !filterMode &&
    (recommendedPortfolios?.some(
      p => modelPortfolio.internalId === p.internalId && modelPortfolio.seriesBaseName === p.seriesBaseName,
    ) ||
      getProductType(modelPortfolio) !== ProductType.MUTUAL_FUND);
  if (filterMode === FilterMode.Recommendation || shouldShowOnPlayback) {
    const portfolioContentItemsBySeries = modelPortfolioContent.all_model_portfolio_data?.items?.filter(
      item => item?.series_base_name === modelPortfolio.seriesBaseName,
    );
    const portfolioContent =
      portfolioContentItemsBySeries?.find(item => item?.internal_ids_list?.includes(modelPortfolio.internalId)) ??
      portfolioContentItemsBySeries?.find(item => !item?.internal_ids_list?.length);

    if (!portfolioContent) {
      console.warn(
        `Portfolio content not found. seriesBaseName: ${modelPortfolio.seriesBaseName}, id: ${modelPortfolio.internalId}`,
      );
    }
    return portfolioContent;
  }
  return undefined;
};

/**
 * Transforms the Symphony fees data into the format the portfolio selection page requires.
 * The frontend components requires MER fee.
 * The data is also formatted as necessary to display on the portfolio selection page and components.
 * @param fees  - The model portfolio allocation fees object.
 * @param notApplicableLabel - N/A label when mer value does not resolve.
 * @param locale - The user's locale.
 * @returns An object containing the transformed fee data.
 */
const getFeesResponse = (
  fees: ModelPortfolioAllocationFees | null,
  notApplicableLabel: string,
  locale?: Locale,
): Allocation['fee'] => {
  const feesResponseFormatOptions: FormatPercentageOptions = {
    decimals: 2,
    removeTrailingZeroes: false,
    locale,
  };
  return {
    effectiveDate: fees?.effectiveDate ? allNumericDateFormat(fees.effectiveDate, { locale }) : '',
    mer: fees?.mer ? formatPercentageString(fees.mer, feesResponseFormatOptions) : notApplicableLabel,
  };
};

/**
 * Transforms the Symphony model portfolio allocations into the format the portfolio selection page requires.
 * This reduces complexity and removes unnecessary fields that the portfolio selection page does not need.
 * The data is also formatted as necessary to display on the portfolio selection page and components.
 * @param allocations - The model portfolio fund allocations object.
 * @param contentPortfolioSelection - The ContentStack data for the portfolio selection page.
 * @param isAriaPortfolio - Boolean indicating `true` if the portfolio is an Aria portfolio, `false` otherwise.
 * @param productType - The portfolio product type.
 * @param locale - The user's locale.
 * @returns An array of Allocations.
 */
const getPortfolioAllocationsData = (
  allocations: ModelPortfolioAllocations[],
  contentPortfolioSelection: PortfolioSelectionContent,
  isAriaPortfolio: boolean,
  productType: ProductType,
  locale?: Locale,
): Allocation[] => {
  const portfolioAllocationsData: Allocation[] = [];
  allocations.forEach(allocation => {
    const security = allocation.recommendedSecurities?.[0].security;
    if (security) {
      portfolioAllocationsData.push({
        assetName: allocation.asset.name,
        targetAllocation: formatPercentageString(allocation.targetAllocation, {
          decimals: 1,
          removeTrailingZeroes: false,
          locale,
        }),
        cusip: security.cusip,
        fee: getFeesResponse(security.fees, contentPortfolioSelection.labels?.not_applicable ?? '', locale),
        fundAllocations: getFundAllocations(
          security.fundAllocations?.[0]?.assetClassAllocations || [],
          isAriaPortfolio,
          productType,
          locale,
        ),
        appropriatenessUrl: getLocalizedPropertyValue(
          security.localizedProperties,
          'appropriatenessUrl',
          getSymphonyLanguageType(locale),
        ),
        fundCategory: security.fundCategory,
        fundCategoryDisplay: getLocalizedPropertyValue(
          security.localizedProperties,
          'fundCategory',
          getSymphonyLanguageType(locale),
        ),
        fundFactsUrl: getLocalizedPropertyValue(
          security.localizedProperties,
          'fundFactsUrl',
          getSymphonyLanguageType(locale),
        ),
        identifier: security.identifier ?? '',
        liveRate: getLiveRateResponse(security.liveRate),
        name: security.name,
        performance: getPerformanceResponse(security.performance, contentPortfolioSelection, locale),
        savingsType: getLocalizedPropertyValue(
          security.localizedProperties,
          'savingsType',
          getSymphonyLanguageType(locale),
        ),
        term: security.term,
        underlyingIdentifier: security.underlyingIdentifier,
      });
    }
  });

  return portfolioAllocationsData;
};

/**
 * Takes the fund's asset class allocations and transform the data for the frontend to consume.
 * For Aria portfolios, do not return the OTHER and CASH_EQUIVALENT asset class. For non-Aria, do not return OTHER.
 * For non mutual fund products (i.e. GIC or Savings), hard code the asset allocation.
 * @param assetClassAllocations - The asset class allocations for the fund.
 * @param isAriaPortfolio - Boolean indicating `true` if the portfolio is an Aria portfolio, `false` otherwise.
 * @param productType - The portfolio product type.
 * @param locale - The user's locale.
 * @returns The fund's asset class allocations.
 */
const getFundAllocations = (
  assetClassAllocations: ModelPortfolioAssetClassAllocations[],
  isAriaPortfolio: boolean,
  productType: ProductType,
  locale?: Locale,
): FundAllocation[] => {
  const allocationFormatOptions: FormatPercentageOptions = { decimals: 1, removeTrailingZeroes: false, locale };
  if (productType !== ProductType.MUTUAL_FUND) {
    return [
      {
        name: AssetClasses.FIXED_INCOME,
        actualAllocation: formatPercentageString('0', allocationFormatOptions),
        targetAllocation: formatPercentageString('0', allocationFormatOptions),
      },
      {
        name: AssetClasses.FOREIGN_EQUITY,
        actualAllocation: formatPercentageString('0', allocationFormatOptions),
        targetAllocation: formatPercentageString('0', allocationFormatOptions),
      },
      {
        name: AssetClasses.CANADIAN_EQUITY,
        actualAllocation: formatPercentageString('0', allocationFormatOptions),
        targetAllocation: formatPercentageString('0', allocationFormatOptions),
      },
      {
        name: AssetClasses.CASH_EQUIVALENT,
        actualAllocation: formatPercentageString('100', allocationFormatOptions),
        targetAllocation: formatPercentageString('100', allocationFormatOptions),
      },
    ];
  }

  const order = [
    AssetClasses.FIXED_INCOME,
    AssetClasses.FOREIGN_EQUITY,
    AssetClasses.CANADIAN_EQUITY,
    AssetClasses.CASH_EQUIVALENT,
  ];

  return assetClassAllocations
    .filter(
      allocation =>
        allocation.asset.name !== AssetClasses.OTHER &&
        (!isAriaPortfolio || allocation.asset.name !== AssetClasses.CASH_EQUIVALENT),
    )
    .map(allocation => ({
      name: allocation.asset.name as AssetClasses,
      actualAllocation: formatPercentageString(allocation.actualAllocation ?? '0.0', allocationFormatOptions),
      targetAllocation: formatPercentageString(allocation.targetAllocation, allocationFormatOptions),
    }))
    .sort((a, b) => {
      return order.indexOf(a.name) - order.indexOf(b.name);
    });
};

/**
 * Calculates the highest total fee from all the portfolio's allocations. A fee is expected for all mutual funds portfolios.
 * If a fee is not able to be calculated, `null` is returned. The UI component can determine how to render the display.
 * @param allocations - The model portfolio fund allocations object.
 * @param locale - The user's locale.
 * @returns The highest fee for the portfolio or null.
 */
export const getHighestFee = (allocations: ModelPortfolioAllocations[], locale?: Locale): Portfolio['fee'] => {
  const highestAllocationFee: ModelPortfolioAllocationFees = allocations.reduce(
    (currentAllocationWithHighestFee, allocation): ModelPortfolioAllocationFees => {
      const fee = allocation.recommendedSecurities?.[0]?.security.fees;
      if (fee?.mer && parseFloat(fee.mer) >= parseFloat(currentAllocationWithHighestFee.mer ?? '0')) {
        return fee;
      }
      return currentAllocationWithHighestFee;
    },
    {} as ModelPortfolioAllocationFees,
  );

  // If portfolio fee cannot be resolved, return null because 0 or N/A is not appropriate.
  // Non-zero fee is expected for mutual funds.
  return highestAllocationFee.mer
    ? {
        effectiveDate: highestAllocationFee.effectiveDate ?? '',
        mer: formatPercentageString(highestAllocationFee.mer, { decimals: 2, removeTrailingZeroes: false, locale }),
      }
    : undefined;
};

/**
 * Given a portfolio allocation, determine whether the allocation is a Market Linked GIC.
 * @param allocations - The model portfolio allocation.
 * @returns Boolean value determining if the portfolio's allocation is a Market Linked GIC.
 */
const isMarketLinkedGic = (allocations: ModelPortfolioAllocations[]): boolean => {
  const savingsType = (allocations[0]?.recommendedSecurities?.[0].security.savingsType || '').toUpperCase();
  return savingsType === 'MARKET LINKED GIC' || savingsType === 'INTERIM MARKET LINKED GIC';
};

/**
 * Given a portfolio allocation, determine whether the allocation is a Savings Accelerator Product.
 * @param allocations - The model portfolio allocation.
 * @returns Boolean value determining if the portfolio's allocation is a Savings Accelerator Product.
 */
const isSavingsAccelerator = (allocations: ModelPortfolioAllocations[]): boolean => {
  const savingsType = (allocations[0]?.recommendedSecurities?.[0].security.savingsType || '').toUpperCase();
  return savingsType === 'SCOTIABANK SAVINGS ACCELERATOR ACCOUNT' || savingsType === 'SAVINGS ACCELERATOR';
};

/**
 * As of PDZ-229 project pivot, Aria series (consisting of build/defend/pay funds) is no longer on Recommendation page.
 * In VAP, there may still be Aria individual funds, as a result, all the product display name will use localized properties.
 * If the value does not resolve, fallback to CMS.
 * If the value still does not resolve, fallback to name from BAM.
 */
const getPortfolioDisplayName = (
  modelPortfolio: ModelPortfolio,
  locale?: Locale,
  portfolioContent?: ModelPortfolioContent | null,
) => {
  const allocations = modelPortfolio.guidance?.diversification?.assets.allocations ?? [];
  const security = allocations[0]?.recommendedSecurities?.[0]?.security;
  const allocationDisplayName = security
    ? getLocalizedPropertyValue(security.localizedProperties, 'displayName', getSymphonyLanguageType(locale))
    : '';
  return allocationDisplayName || portfolioContent?.model_portfolio_name || modelPortfolio.name;
};

/**
 * Transforms the Symphony performance data into the format the portfolio selection page requires.
 * This reduces complexity and removes unnecessary fields that the portfolio selection page does not need.
 * The data is also formatted as necessary to display on the portfolio selection page and components.
 * @param performance - The model portfolio allocation performance object.
 * @param contentPortfolioSelection - The ContentStack data for the portfolio selection page.
 * @param locale - The user's locale.
 * @returns An object containing the transformed performance data.
 */
const getPerformanceResponse = (
  performance: ModelPortfolioAllocationPerformance | null,
  contentPortfolioSelection: PortfolioSelectionContent,
  locale?: Locale,
) => ({
  fiveYear: getPerformanceValue(performance?.fiveYear?.cumulative, contentPortfolioSelection, locale),
  inception: getPerformanceValue(performance?.inception?.cumulative, contentPortfolioSelection, locale),
  oneYear: getPerformanceValue(performance?.oneYear?.cumulative, contentPortfolioSelection, locale),
  tenYear: getPerformanceValue(performance?.tenYear?.cumulative, contentPortfolioSelection, locale),
  threeYear: getPerformanceValue(performance?.threeYear?.cumulative, contentPortfolioSelection, locale),
});

/**
 * Format and return the display value for the performance data or the not applicable value from ContentStack.
 * @param value The Symphony performance value.
 * @param contentPortfolioSelection - The ContentStack data for the portfolio selection page.
 * @param locale - The user's locale.
 * @returns The formatted performance value or the not applicable value.
 */
const getPerformanceValue = (
  value: string | undefined,
  contentPortfolioSelection: PortfolioSelectionContent,
  locale?: Locale,
) =>
  value
    ? formatPercentageString(value, { decimals: 2, removeTrailingZeroes: false, locale })
    : contentPortfolioSelection.labels?.not_applicable;

/**
 * Get an array of Portfolio objects given the ModelPortfolio data.
 * @param args - The arguments required to build the portfolio list.
 * @param args.ariaWhitelistedSecurityIds - Aria funds that will have minimum investment values; N/A otherwise.
 * @param args.contentPortfolioSelection - The ContentStack data for the portfolio selection page.
 * @param args.filterMode - The portfolio selection filter criteria.
 * @param args.locale - The user's locale.
 * @param args.modelPortfolioContent - The model portfolio ContentStack data.
 * @param args.modelPortfolios - The model portfolios array.
 * @param args.recommendedPortfolios - The recommended portfolios array.
 * @param args.recommendedProductData - The recommended product data.
 * @returns An array of Portfolio objects.
 */
export const getPortfolios = ({
  ariaWhitelistedSecurityIds,
  contentPortfolioSelection,
  filterMode,
  locale,
  modelPortfolioContent,
  modelPortfolios,
  recommendedPortfolios,
  recommendedProductData,
}: {
  ariaWhitelistedSecurityIds: string[];
  contentPortfolioSelection: PortfolioSelectionContent;
  filterMode?: FilterMode;
  locale?: Locale;
  modelPortfolioContent: GetModelPortfolioContent;
  modelPortfolios: ModelPortfolio[];
  recommendedPortfolios?: Portfolio[];
  recommendedProductData?: GetRecommendedProduct;
}): Portfolio[] =>
  modelPortfolios.reduce<Portfolio[]>((acc, modelPortfolio) => {
    const productType = getProductType(modelPortfolio);
    const productVariant = getProductVariant(modelPortfolio);
    const { seriesBaseName, seriesId } = modelPortfolio;

    const invalidPortfolioMessage = `Invalid portfolio [series:${modelPortfolio.seriesBaseName}, internalId:${modelPortfolio.internalId}]`;
    if (
      !productType ||
      !seriesBaseName ||
      seriesId === null ||
      Object.values(modelPortfolio).some(value => value === null)
    ) {
      console.error(`${invalidPortfolioMessage}: contains some null portfolio values`);
      return acc;
    }

    const portfolioContent = getPortfolioContent(
      modelPortfolioContent,
      modelPortfolio,
      filterMode,
      recommendedPortfolios,
    );
    const allocations = modelPortfolio.guidance?.diversification?.assets.allocations ?? [];
    const modelPortfolioSecurity = allocations[0]?.recommendedSecurities?.[0]?.security;
    const isAriaPortfolio = isAria(modelPortfolio, modelPortfolio.seriesBaseName);
    const calculatedRiskScore = recommendedProductData?.managedProduct?.calculatedRecommendations?.riskScore;
    const isHighRisk =
      !isAriaPortfolio &&
      !!calculatedRiskScore &&
      !!modelPortfolio.riskRange?.max &&
      modelPortfolio.riskRange.max > calculatedRiskScore;

    const minimumInvestment =
      isAriaPortfolio && !ariaWhitelistedSecurityIds.some(a => a === modelPortfolioSecurity?.identifier)
        ? contentPortfolioSelection.aria_portfolios?.[0]?.unavailable_minimum_investment ?? 'N/A'
        : formatCurrency(modelPortfolioSecurity?.identifier === 'BNS374' ? 100 : 500, { locale });

    acc.push({
      allocations: getPortfolioAllocationsData(
        allocations,
        contentPortfolioSelection,
        isAriaPortfolio,
        productType,
        locale,
      ),
      fee: productType === ProductType.MUTUAL_FUND ? getHighestFee(allocations, locale) : undefined,
      infoUrl:
        productType !== ProductType.MUTUAL_FUND
          ? getInfoUrl(contentPortfolioSelection, modelPortfolioSecurity)
          : undefined,
      internalId: modelPortfolio.internalId as number,
      isAria: isAriaPortfolio,
      isHighRisk,
      isMarketLinkedGic: isMarketLinkedGic(allocations),
      isSavingsAccelerator: isSavingsAccelerator(allocations),
      minimumInvestment,
      name: getPortfolioDisplayName(modelPortfolio, locale, portfolioContent),
      description: portfolioContent?.model_portfolio_description_redactor || null,
      benefits: portfolioContent?.benefits?.map(b => b?.benefit ?? '') ?? [],
      rates: getPortfolioRate(locale, modelPortfolioSecurity?.liveRate?.rates),
      term: getPortfolioTerm(contentPortfolioSelection, allocations[0]?.recommendedSecurities?.[0]?.security?.term),
      productCode: productType !== ProductType.SAVINGS ? modelPortfolioSecurity?.identifier : null,
      productType,
      riskRange: {
        max: modelPortfolio.riskRange?.max || null,
        min: modelPortfolio.riskRange?.min || null,
      },
      seriesBaseName,
      seriesId,
      seriesTBenefits: portfolioContent?.secondary_benefits?.map(b => b?.benefit ?? '') ?? [],
      seriesType: productVariant,
    });
    return acc;
  }, []);

/**
 *
 * @param portfolios - Incoming list of Portfolios.
 * @returns - Return a sorted list of disclaimers.
 */
export const getDisclaimersList = (portfolios: Portfolio[]): Disclaimer[] => {
  const DISCLAIMER_ORDER = [
    ProductDisclaimerTypes.MUTUAL_FUND_MONEY_MARKET,
    ProductDisclaimerTypes.MUTUAL_FUND,
    ProductDisclaimerTypes.SCOTIA_FUND,
    ProductDisclaimerTypes.GIC,
  ];
  const sortOrder = (a: ProductDisclaimerTypes, b: ProductDisclaimerTypes) =>
    DISCLAIMER_ORDER.indexOf(a) - DISCLAIMER_ORDER.indexOf(b);

  const disclaimersToShow = new Set<ProductDisclaimerTypes>();
  portfolios.forEach(portfolio => {
    if (portfolio.productType === ProductType.MUTUAL_FUND) {
      disclaimersToShow.add(ProductDisclaimerTypes.SCOTIA_FUND);
    }
    if (
      portfolio.productType === ProductType.MUTUAL_FUND &&
      portfolio.allocations[0].fundCategory === FundCategoryTypes.UNKNOWN
    ) {
      disclaimersToShow.add(ProductDisclaimerTypes.MUTUAL_FUND);
    }
    if (
      portfolio.productType === ProductType.MUTUAL_FUND &&
      portfolio.allocations[0].fundCategory === FundCategoryTypes.CASH_EQUIVALENT
    ) {
      disclaimersToShow.add(ProductDisclaimerTypes.MUTUAL_FUND_MONEY_MARKET);
    }
    if (portfolio.productType === ProductType.GIC || portfolio.productType === ProductType.SAVINGS) {
      disclaimersToShow.add(ProductDisclaimerTypes.GIC);
    }
  });

  return Array.from(disclaimersToShow)
    .sort(sortOrder)
    .reduce<Disclaimer[]>((acc, curr) => {
      acc.push({ key: curr });
      return acc;
    }, []);
};

/**
 * Get the info url for GIC/HISA's savingsType.
 * Multiple savingsType may share the same URL.  If Contentstack returns more than one match, get the first hit.
 */
export const getInfoUrl = (
  contentPortfolioSelection: PortfolioSelectionContent,
  modelPortfolioSecurity?: ModelPortfolioAllocationSecurity,
): string | undefined => {
  const savingsType = contentPortfolioSelection.labels?.gic_labelsConnection?.edges?.[0]?.node?.info?.savings_type;
  const infoUrl = savingsType?.find(s => s?.types?.some(t => t === modelPortfolioSecurity?.savingsType))?.url;
  return infoUrl ?? undefined;
};

/**
 * The LiveRate response modified as LiveRate object.
 * @param liveRate - Incoming LiveRate.
 * @returns LiveRate object.
 */
export const getLiveRateResponse = (liveRate: ModelPortfolioAllocationsLiveRate | null): LiveRate | null => {
  if (liveRate) {
    const { __typename, rates, term, ...tempLive } = liveRate;

    return {
      ...tempLive,
      rates: {
        interimRate: rates.interimRate,
        maximumRate: rates.maximumRate,
        targetRate: rates.targetRate,
      },
      term: {
        displayPeriod: term.displayPeriod,
        displayUnit: term.displayUnit,
        end: term.end,
        start: term.start,
        unit: term.unit,
      },
    };
  }
  return null;
};

/**
 * Modifies the different rates within the rate object to a localized formatted display.
 * @param locale - Locale used to formate the rates in the correct language format.
 * @param rate - Incoming Rate object from liveRate.
 * @returns - Modified rate object with the formatted localized percentage string for each rate.
 */
export const getPortfolioRate = (locale?: Locale, rate?: Rate | null): Rate | null => {
  if (!rate) {
    return null;
  }

  const liveRateResponseFormatOptions: FormatPercentageOptions = {
    decimals: 4,
    removeTrailingZeroes: false,
    locale,
  };
  return {
    interimRate: rate.interimRate ? formatPercentageString(rate.interimRate, liveRateResponseFormatOptions) : null,
    maximumRate: rate.maximumRate ? formatPercentageString(rate.maximumRate, liveRateResponseFormatOptions) : null,
    targetRate: formatPercentageString(rate.targetRate, liveRateResponseFormatOptions),
  };
};

/**
 * Returns the modified portfolioTerm text from the liveRate.term of the portfolio.
 * @param contentPortfolioSelection - Portfolio content.
 * @param portfolioTerm - Term object belonging to the portfolio.
 * @returns The formatted portfolios term (18 Months, 2 Years, 188 Days, etc.) in the given locale.
 */
export const getPortfolioTerm = (
  contentPortfolioSelection: PortfolioSelectionContent,
  portfolioTerm?: Term | null,
): string | null => {
  if (!portfolioTerm) {
    return null;
  }

  const unitNodeContent = contentPortfolioSelection.labels?.gic_labelsConnection?.edges?.[0]?.node;
  const displayPeriod = portfolioTerm.displayPeriod;
  const displayUnit = portfolioTerm.displayUnit;
  switch (displayUnit) {
    case RateUnitType.MONTH:
      return `${displayPeriod} ${displayPeriod > 1 ? unitNodeContent?.months : unitNodeContent?.month}`;
    case RateUnitType.YEAR:
      return `${displayPeriod} ${displayPeriod > 1 ? unitNodeContent?.years : unitNodeContent?.year}`;
    case RateUnitType.DAY:
      return `${displayPeriod} ${unitNodeContent?.days}`;
  }
};

export const getGicSortIndex = (item: string, sorOrder: Record<string, number>): number => {
  const key = Object.keys(sorOrder).find(el => {
    if (el === GicHisaTypes.Redeemable) {
      return item.includes(el) && !item.includes('Non');
    }
    return item.includes(el);
  });
  return getSortOrderValue(sorOrder, key);
};

export const getTypesWithIframeUrl = (portfolios: Portfolio[]): Record<string, string | null> =>
  portfolios.reduce<Record<string, string | null>>((acc, p) => {
    const allocations = p.allocations[0];
    const key = allocations.savingsType?.includes(GicHisaTypes.NonRedeemable)
      ? GicHisaTypes.NonRedeemable
      : allocations.savingsType;
    // TODO: get url from localised property for GIC/HISA
    if (key) {
      acc[key] = allocations.appropriatenessUrl;
    }
    return acc;
  }, {});

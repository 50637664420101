export enum PortfolioTags {
  BASE_PRODUCT_TAG = 'BASE_PRODUCT',
  IS_ARIA_TAG = 'IS_ARIA',
  PRODUCT_TYPE_TAG = 'PRODUCT_TYPE',
  PRODUCT_VARIANT_TAG = 'PRODUCT_VARIANT',
}

export enum ProductType {
  GIC = 'GIC',
  MUTUAL_FUND = 'MF',
  SAVINGS = 'Savings',
}

export enum ProductVariant {
  SERIES_A = 'A',
  SERIES_T = 'T',
}

export interface BaseProductTag {
  name: PortfolioTags.BASE_PRODUCT_TAG;
  value: string;
}
interface ProductTypeTag {
  name: PortfolioTags.PRODUCT_TYPE_TAG;
  value: ProductType;
}
interface IsAriaTag {
  name: PortfolioTags.IS_ARIA_TAG;
  value: boolean;
}
interface ProductVariantTag {
  name: PortfolioTags.PRODUCT_VARIANT_TAG;
  value: ProductVariant;
}

// eslint-disable-next-line prettier/prettier
export type PortfolioTag<T extends PortfolioTags> =
 T extends PortfolioTags.BASE_PRODUCT_TAG ? BaseProductTag :
 T extends PortfolioTags.IS_ARIA_TAG ? IsAriaTag : 
 T extends PortfolioTags.PRODUCT_TYPE_TAG ? ProductTypeTag :
 T extends PortfolioTags.PRODUCT_VARIANT_TAG ? ProductVariantTag : 
 never;

import './index.css';
// IE11 polyfills
import 'core-js';
import 'cross-fetch/polyfill';
// IE11 polyfill needed for the PDF downloader to work correctly without causeing long script errors
import 'fastestsmallesttextencoderdecoder';
// IE11 polyfills for using Intl.NumberFormat for currencies
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/fr';

import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React, { StrictMode } from 'react';
import { LiveAnnouncer } from 'react-aria-live';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import {
  CoreConfigProvider,
  createTheme,
  initializeNewRelic,
  PostHogProvider,
  sfPosthog,
  ThemeProvider,
} from '@sigfig/digital-wealth-core';

import App from './App';
import config, { coreConfigs, isProd, isSqa9 } from './config';
import { AppProvider } from './contexts/App';
import * as serviceWorker from './serviceWorker';
import { defaultTheme } from './theme';

initializeNewRelic(config.newRelic.licenseKey, config.newRelic.applicationId);

const getLogLevels = () => {
  if (isProd) {
    return ['error'];
  }
  if (process.env.NODE_ENV === 'development' || isSqa9) {
    // SQA9 is a load testing environment, so turn off logging.
    return [];
  }
  return ['warn', 'error'];
};

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.sentry.environment,
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsoleIntegration({
      levels: getLogLevels(), // TODO: revisit this after warnings are cleaned up in https://sigfig.atlassian.net/browse/ANR-7195
    }),
  ],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <Sentry.ErrorBoundary>
        <PostHogProvider apiKey={config.posthog.token} options={sfPosthog.DEFAULT_CONFIG}>
          <ThemeProvider theme={createTheme(defaultTheme)}>
            <CoreConfigProvider value={coreConfigs}>
              <LiveAnnouncer>
                <AppProvider>
                  <App />
                </AppProvider>
              </LiveAnnouncer>
            </CoreConfigProvider>
          </ThemeProvider>
        </PostHogProvider>
      </Sentry.ErrorBoundary>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

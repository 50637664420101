import { Allocation, FundAllocation } from '../../containers/PortfolioSelection/types';
import { getPortfolioCompositionHeading } from '../../containers/PortfolioSelection/utils';
import {
  FeesContent,
  PerformanceAnalysisContent,
  PortfolioCompositionContent,
} from '../../hooks/portfolio-selection/contentstack';
import { AssetClasses } from '../../hooks/portfolio-selection/symphony';

import { Props as CompositionProps } from './PortfolioComposition';
import { Props } from './PortfolioTableData';

export const getSavingsCompositionData = (
  allocations?: FundAllocation[],
  content?: PortfolioCompositionContent | null,
): CompositionProps => {
  if (!allocations || !content) {
    return { allocations: [] };
  }
  const GIC_SORT_ORDER = [
    AssetClasses.CANADIAN_EQUITY,
    AssetClasses.FOREIGN_EQUITY,
    AssetClasses.FIXED_INCOME,
    AssetClasses.CASH_EQUIVALENT,
  ];

  allocations.sort((a, b) => GIC_SORT_ORDER.indexOf(a.name) - GIC_SORT_ORDER.indexOf(b.name));
  return {
    allocations: allocations.map(a => ({
      key: a.name,
      label: getPortfolioCompositionHeading(content, a.name) ?? '',
      value: a.targetAllocation,
    })),
  };
};

export const getPerformanceAnalysisTableData = (
  performanceContent?: PerformanceAnalysisContent | null,
  allocations?: Allocation[],
  compositionContent?: PortfolioCompositionContent | null,
): Omit<Props, 'dataQa'> => {
  if (!allocations || !compositionContent || !performanceContent) {
    return { tableData: { tableHeaders: [], tableRows: [] } };
  }

  const tableHeaders = [
    { name: 'compound', value: performanceContent.compound_returns ?? '' },
    { name: 'yr-1', value: `1 ${performanceContent.year ?? ''}` },
    { name: 'yr-3', value: `3 ${performanceContent.year ?? ''}` },
    { name: 'yr-5', value: `5 ${performanceContent.year ?? ''}` },
    { name: 'yr-10', value: `10 ${performanceContent.year ?? ''}` },
    { name: 'inception', value: performanceContent.inception ?? '' },
  ];
  const tableRows = allocations.map(({ performance }) => [
    { name: 'fund', value: performanceContent.fund ?? '' },
    { name: 'yr-1', value: performance.oneYear ?? '' },
    { name: 'yr-3', value: performance.threeYear ?? '' },
    { name: 'yr-5', value: performance.fiveYear ?? '' },
    { name: 'yr-10', value: performance.tenYear ?? '' },
    { name: 'inception', value: performance.inception ?? '' },
  ]);

  return { tableData: { tableHeaders, tableRows } };
};

export const getFeesTableData = (
  allocations?: Allocation[],
  feesContent?: FeesContent | null,
): Omit<Props, 'dataQa'> => {
  if (!allocations || !feesContent) {
    return { tableData: { tableHeaders: [], tableRows: [] } };
  }

  const label = { mer: 'mer', date: 'effective-date' };
  const tableHeaders = [
    { name: label.mer, value: feesContent.mer ?? '' },
    { name: label.date, value: feesContent.effective_date ?? '' },
  ];

  const tableRows = allocations.map(({ fee }) => [
    { name: label.mer, value: fee.mer },
    { name: label.date, value: fee.effectiveDate },
  ]);

  return { tableData: { tableHeaders, tableRows } };
};

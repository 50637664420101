import { ArrowBackIosNew, Help, Home, SupervisedUserCircle } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Divider,
  Dropdown,
  Grid,
  RteContent,
  Tooltip,
  Typography,
  useLocale,
  useTheme,
} from '@sigfig/digital-wealth-core';

import { useCurrentPage } from '../../App/hooks/useCurrentPage';
import { PageRoute, routes } from '../../App/routes';
import { useAuth0 } from '../../hooks/auth0';
import { useClientInfo } from '../../hooks/client/useClientInfo';

import { useHeaderContent } from './hooks/useHeaderContent';

export interface Props {
  dataQa?: string;
}

export const Header: React.FC<Props> = ({ dataQa = 'header' }) => {
  const [, setLocale] = useLocale();
  const {
    bnsHeader: { root, styles },
  } = useTheme();
  const { user } = useAuth0();
  const currentPage = useCurrentPage();
  const navigate = useNavigate();

  const { data: clientNameData } = useClientInfo({
    skip: !currentPage || ![PageRoute.Onboarding, PageRoute.OnboardingStep].includes(currentPage),
  });
  const { data: headerData } = useHeaderContent();

  const onBackButtonClick = () => {
    navigate(routes.accountSummary());
  };

  if (!headerData) {
    return null;
  }

  return (
    <Box component="header" data-qa={dataQa} sx={root}>
      <Grid alignItems="center" container>
        <Grid alignSelf="center" data-qa={`${dataQa}-logo`} item px={5} sm={4} xs="auto">
          <img alt={headerData.logo.alt} src={headerData.logo.src} width="109.85px" />
        </Grid>
        <Grid alignItems="center" container item justifyContent="flex-end" sm spacing={2} xs={12}>
          {headerData.backToPartner && (
            <Grid item sm="auto" xs={12}>
              <Button
                color="inherit"
                onClick={user?.returnUrl ? () => window.location.assign(user.returnUrl) : undefined}
                size="large"
                startIcon={<Home />}
                sx={{ typography: 'subtitle2' }}
              >
                {headerData.backToPartner}
              </Button>
            </Grid>
          )}
          <Grid item sm="auto" xs={12}>
            <Tooltip
              tooltipContent={
                <>
                  <Typography component="div" variant="subtitle1">
                    {headerData.tooltip.header}
                  </Typography>
                  <RteContent data={headerData.tooltip.body} />
                </>
              }
            >
              <Grid item xs="auto">
                <Button
                  color="inherit"
                  data-qa={`${dataQa}-help-button`}
                  size="large"
                  startIcon={<Help />}
                  sx={{ typography: 'subtitle2' }}
                >
                  {headerData.tooltip.cta}
                </Button>
              </Grid>
            </Tooltip>
          </Grid>
          <Grid item sm="auto" xs={12}>
            <Dropdown
              data-qa={`${dataQa}-language-toggle`}
              disableUnderline
              inputProps={{ sx: styles.dropdownLabel }}
              items={headerData.languageOptions}
              onChange={(event: any) => setLocale(event.target.value)}
              value={headerData.currentLanguage}
              variant="madlib"
            />
          </Grid>

          <Grid item sm="auto" xs={12}>
            {headerData.backToDashboard && (
              <Button
                data-qa={`${dataQa}-back-to-summary-button`}
                onClick={onBackButtonClick}
                sx={{ ...styles.accountSummaryButton }}
                variant="outlined"
              >
                {headerData.backToDashboard}
              </Button>
            )}
            {headerData.advisor && (
              <Box data-qa={`${dataQa}-account-summary-user-header`} sx={{ ...styles.accountSummaryAdvisor }}>
                <SupervisedUserCircle sx={{ verticalAlign: 'text-bottom' }} />
                <Typography component="div" sx={{ color: 'inherit', ml: 1 }} variant="subtitle2">
                  {headerData.advisor}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      {headerData.backButton && (
        <Button
          data-qa={`${dataQa}-cta-back`}
          onClick={onBackButtonClick}
          startIcon={<ArrowBackIosNew />}
          sx={{
            '&.MuiButton-root': {
              px: 6,
            },
            '& .MuiSvgIcon-root': {
              fontSize: 12,
            },
          }}
        >
          <Typography sx={{ color: 'primary.main' }} variant="body2">
            {headerData.backButton}
          </Typography>
        </Button>
      )}
      {clientNameData?.name && (
        <>
          <Box data-qa={`${dataQa}-subheader`} py={2} sx={{ backgroundColor: 'background.default' }}>
            <RteContent
              config={{ clientName: clientNameData.name }}
              data={headerData.subHeader}
              sx={{ textAlign: 'center' }}
              variantMapping={{ h6: 'div' }}
            />
          </Box>
          <Divider />
        </>
      )}
    </Box>
  );
};

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import * as React from 'react';

import {
  Box,
  CompactVariant,
  Grid,
  RecommendedProductData,
  RiskBand,
  RiskSpectrum,
  Stack,
  Typography,
  useIsScreenSize,
  zipperStrings,
} from '@sigfig/digital-wealth-core';

import { useApp } from '../../contexts/App';

import { SummaryGroup } from './SummaryGroup';

export interface Props {
  dataQa?: string;
  recommendedProductData: RecommendedProductData | undefined;
  selectedRiskBand: RiskBand | undefined;
}

export const RecommendationSummary: React.FC<Props> = ({
  dataQa = 'recommendation-summary',
  recommendedProductData,
  selectedRiskBand,
}) => {
  const isMobile = useIsScreenSize('sm');
  const { contentOptions } = useApp();

  return (
    <Grid container data-qa={dataQa} spacing={{ sm: 4, xs: 2 }}>
      <Grid item sm="auto" xs={12}>
        <Box sx={{ width: '143px' }}>
          {recommendedProductData && (
            <RiskSpectrum
              {...recommendedProductData}
              compact={CompactVariant.Block}
              contentOptions={contentOptions}
              riskBandHeight={3}
              riskBandHeightPrimary={6}
            />
          )}
        </Box>
        <Typography component="h2" data-qa={`${dataQa}-label`} variant="h3">
          {selectedRiskBand?.labelSummary || selectedRiskBand?.label}
        </Typography>
      </Grid>
      <Grid alignContent="end" container item sm xs={12}>
        <Stack
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          direction={{ xs: 'column', sm: 'row' }}
          divider={
            !isMobile && <FiberManualRecordIcon sx={({ palette }) => ({ color: palette.divider, fontSize: 16 })} />
          }
          justifyContent="space-between"
          spacing={{ xs: 1, sm: 2 }}
        >
          <SummaryGroup
            dataQa={`${dataQa}-attributes-timeHorizon`}
            label={recommendedProductData?.attributes?.timeHorizon?.label}
            value={{
              text: recommendedProductData?.attributes?.timeHorizon?.value,
              ariaLabel: `${recommendedProductData?.attributes?.timeHorizon?.ariaLabel} ${recommendedProductData?.attributes?.timeHorizon?.label}`,
            }}
          />
          <SummaryGroup
            dataQa={`${dataQa}-attributes-objective`}
            label={recommendedProductData?.attributes?.objective?.label}
            value={{
              text: recommendedProductData?.attributes?.objective?.value,
              ariaLabel: `${recommendedProductData?.attributes?.objective?.value} ${recommendedProductData?.attributes?.objective?.label}`,
            }}
          />
          <SummaryGroup
            dataQa={`${dataQa}-attributes-riskProfile`}
            label={recommendedProductData?.attributes?.riskProfile?.label}
            value={{
              text: recommendedProductData?.attributes?.riskProfile?.value,
              ariaLabel: `${recommendedProductData?.attributes?.riskProfile?.value} ${recommendedProductData?.attributes?.riskProfile?.label}`,
            }}
          />
          <SummaryGroup
            dataQa={`${dataQa}-target-allocations`}
            label={recommendedProductData?.cms.target_allocations?.label ?? undefined}
            value={{
              text: recommendedProductData?.riskBands[recommendedProductData.riskBandNew]?.targetAllocations?.replace(
                /\//g,
                ' / ',
              ),
              ariaLabel: zipperStrings(
                recommendedProductData?.cms.target_allocations?.label ?? '',
                recommendedProductData?.riskBands[recommendedProductData.riskBandNew]?.targetAllocations ?? '',
              ),
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

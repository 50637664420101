import { PortfolioTag, PortfolioTags, ProductType, ProductVariant } from './types';

export const getTag = <T extends PortfolioTags>(
  name: T,
  portfolio?: {
    tags:
      | { booleanValue?: boolean; decimalValue?: string; integerValue?: number; name: string; stringValue?: string }[]
      | null;
  } | null,
): PortfolioTag<T> | undefined => {
  const tag = portfolio?.tags?.find(t => t.name === name);

  if (name === 'BASE_PRODUCT' && tag?.stringValue !== undefined) {
    return <PortfolioTag<T>>{ name, value: tag.stringValue };
  }

  if (name === 'IS_ARIA' && tag?.booleanValue !== undefined) {
    return <PortfolioTag<T>>{ name, value: tag.booleanValue };
  }

  if (
    name === 'PRODUCT_TYPE' &&
    tag?.stringValue !== undefined &&
    Object.values<string>(ProductType).includes(tag.stringValue)
  ) {
    return <PortfolioTag<T>>{ name, value: tag.stringValue };
  }

  if (
    name === 'PRODUCT_VARIANT' &&
    tag?.stringValue &&
    Object.values<string>(ProductVariant).includes(tag.stringValue)
  ) {
    return <PortfolioTag<T>>{ name, value: tag.stringValue };
  }
};
